import React from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';
import PersonImage from '../../imagens/person.png';

const CharCard = ({ char }) => {
  const ragnarokClasses = [
    { id: 0, name: "Novice" },
    { id: 1, name: "Swordman" },
    { id: 2, name: "Magican" },
    { id: 3, name: "Archer" },
    { id: 4, name: "Acolyte" },
    { id: 5, name: "Merchant" },
    { id: 6, name: "Thief" },
    { id: 7, name: "Knight" },
    { id: 8, name: "Priest" },
    { id: 9, name: "Wizard" },
    { id: 10, name: "Blackmith" },
    { id: 11, name: "Hunter" },
    { id: 12, name: "Assassin" },
    { id: 14, name: "Crusader" }, // Nota: pulou o ID 13
    { id: 15, name: "Monk" },
    { id: 16, name: "Sage" },
    { id: 17, name: "Rogue" },
    { id: 18, name: "Alchemist" },
    { id: 19, name: "Bard" },
    { id: 20, name: "Dancer" },
    { id: 4001, name: "Novice High" },
    { id: 4002, name: "Swordman High" },
    { id: 4003, name: "Magican High" },
    { id: 4004, name: "Archer High" },
    { id: 4005, name: "Acolyte High" },
    { id: 4006, name: "Merchant High" },
    { id: 4007, name: "Thief High" },
    { id: 4008, name: "Lord Knight" },
    { id: 4009, name: "High Priest" },
    { id: 4010, name: "High Wizard" },
    { id: 4011, name: "Whitesmith" },
    { id: 4012, name: "Sniper" },
    { id: 4013, name: "Assassin Cross" },
    { id: 4014, name: "Paladin" },
    { id: 4015, name: "Champion" },
    { id: 4016, name: "Professor" },
    { id: 4017, name: "Stalker" },
    { id: 4018, name: "Creator" },
    { id: 4019, name: "Clown" },
    { id: 4020, name: "Gypsy" },
    { id: 4054, name: "Rune Knight" },
    { id: 4055, name: "Warlock" },
    { id: 4056, name: "Ranger" },
    { id: 4057, name: "Arch Bishop" },
    { id: 4058, name: "Mechanic" },
    { id: 4059, name: "Guillotine Cross" },
    { id: 4066, name: "Royal Guard" },
    { id: 4067, name: "Sorcerer" },
    { id: 4068, name: "Minstrel" },
    { id: 4069, name: "Wanderer" },
    { id: 4070, name: "Sura" },
    { id: 4071, name: "Genetic" },
    { id: 4072, name: "Shadow Chaser" },
    { id: 4060, name: "Rune Knight" },
    { id: 4061, name: "Warlock" },
    { id: 4062, name: "Ranger" },
    { id: 4063, name: "Arch Bishop" },
    { id: 4064, name: "Mechanic" },
    { id: 4065, name: "Guillotine Cross" },
    { id: 4073, name: "Royal Guard" },
    { id: 4074, name: "Sorcerer" },
    { id: 4075, name: "Minstrel" },
    { id: 4076, name: "Wanderer" },
    { id: 4077, name: "Sura" },
    { id: 4078, name: "Genetic" },
    { id: 4079, name: "Shadow Chaser" },
    { id: 4252, name: "Dragon Knight" },
    { id: 4253, name: "Master" },
    { id: 4254, name: "Shadow Cross" },
    { id: 4255, name: "Arch Mage" },
    { id: 4256, name: "Cardinal" },
    { id: 4257, name: "Windhawk" },
    { id: 4258, name: "Imperial Guard" },
    { id: 4259, name: "Biolo" },
    { id: 4260, name: "Abyss Chaser" },
    { id: 4261, name: "Elemental Master" },
    { id: 4262, name: "Inquisitor" },
    { id: 4263, name: "Troubadour" },
    { id: 23, name: "Super Novice" },
    { id: 24, name: "Gunslinger" },
    { id: 25, name: "Ninja" },
    { id: 4045, name: "Super Baby" },
    { id: 4046, name: "Taekwon" },
    { id: 4047, name: "Star Gladiator" },
    { id: 4049, name: "Soul Linker" },
    { id: 4190, name: "Ex-Super Novice" },
    { id: 4191, name: "Ex-Super Baby" },
    { id: 4211, name: "Kagerou" },
    { id: 4212, name: "Oboro" },
    { id: 4215, name: "Rebellion" },
    { id: 4218, name: "Summoner" },
    { id: 4023, name: "Baby Novice" },
    { id: 4024, name: "Baby Swordman" },
    { id: 4025, name: "Baby Magician" },
    { id: 4026, name: "Baby Archer" },
    { id: 4027, name: "Baby Acolyte" },
    { id: 4028, name: "Baby Merchant" },
    { id: 4029, name: "Baby Thief" },
    { id: 4030, name: "Baby Knight" },
    { id: 4031, name: "Baby Priest" },
    { id: 4032, name: "Baby Wizard" },
    { id: 4033, name: "Baby Blacksmith" },
    { id: 4034, name: "Baby Hunter" },
    { id: 4035, name: "Baby Assassin" },
    { id: 4037, name: "Baby Crusader" },
    { id: 4038, name: "Baby Monk" },
    { id: 4039, name: "Baby Sage" },
    { id: 4040, name: "Baby Rogue" },
    { id: 4041, name: "Baby Alchemist" },
    { id: 4042, name: "Baby Bard" },
    { id: 4043, name: "Baby Dancer" },
    { id: 4096, name: "Baby Rune Knight" },
    { id: 4097, name: "Baby Warlock" },
    { id: 4098, name: "Baby Ranger" },
    { id: 4099, name: "Baby Arch Bishop" },
    { id: 4100, name: "Baby Mechanic" },
    { id: 4101, name: "Baby Guillotine Cross" },
    { id: 4102, name: "Baby Royal Guard" },
    { id: 4103, name: "Baby Sorcerer" },
    { id: 4104, name: "Baby Minstrel" },
    { id: 4105, name: "Baby Wanderer" },
    { id: 4106, name: "Baby Sura" },
    { id: 4107, name: "Baby Genetic" },
    { id: 4108, name: "Baby Shadow Chaser" },
    { id: 4220, name: "Baby Summoner" },
    { id: 4222, name: "Baby Ninja" },
    { id: 4223, name: "Baby Kagerou" },
    { id: 4224, name: "Baby Oboro" },
    { id: 4225, name: "Baby Taekwon" },
    { id: 4226, name: "Baby Star Gladiator" },
    { id: 4227, name: "Baby Soul Linker" },
    { id: 4228, name: "Baby Gunslinger" },
    { id: 4229, name: "Baby Rebellion" },
    { id: 4241, name: "Baby Star Emperor" },
    { id: 4242, name: "Baby Soul Reaper" },
    // Adicione mais classes conforme necessário
  ];
  const classToDisplay = ragnarokClasses.find(classe => classe.id === char.class);

  const cardStyle = {
    textAlign: 'center', // Alinha o texto ao centro
    display: 'flex', // Utiliza Flexbox
    flexDirection: 'column', // Orientação da coluna
    justifyContent: 'center', // Centraliza verticalmente
    backgroundImage: `url(${PersonImage})`,
    backgroundSize: 'cover', // Cobrir todo o card com a imagem
    backgroundPosition: 'top',
    marginTop: '10px',
     // Centralizar a imagem no card
  };
  // Estilos para o conteúdo do card
  const cardContentStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '5px',
    padding: '0px',
    margin: '0px',
    backgroundImage: `url(${PersonImage})`,
    backgroundSize: 'cover', // Ajusta a imagem para caber no card sem cortar
    backgroundPosition: 'top', // Centraliza a imagem no card
    backgroundRepeat: 'no-repeat',
  };
  
  const cardTittleStyle = {
    background: 'linear-gradient(to bottom, rgba(50, 75, 125, 1), rgba(100, 150, 255, 0.7))',
    //backgroundColor: 'rgba(100, 150, 255)', // Fundo branco semi-transparente para melhor leitura
    margin: '0px',
    borderRadius: '5px 5px 0px 0px',
    color: 'white',
    padding: '5px',
  };
  const cardTextStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Fundo branco semi-transparente para melhor leitura
    margin: '5px 15px 5px 15px',
    borderRadius: '5px 5px 5px 5px',
    textAlign: 'Left',
    paddingLeft: '15px',
  };
  return (
    <Col md={4}>
      <Card style={cardStyle}>
        <CardBody style={cardContentStyle}>
          <CardTitle style={cardTittleStyle}><b>{char.name}</b></CardTitle>
          <CardText style={cardTextStyle}><b>Classe: {classToDisplay ? classToDisplay.name : char.class}</b></CardText>
          <Row>
            <Col md={6}>
              <CardText style={cardTextStyle}><b>Level:</b> {char.base_level}</CardText>
              <CardText style={cardTextStyle}><b>Job Level:</b> {char.job_level}</CardText>
              <CardText style={cardTextStyle}><b>HP:</b> {char.max_hp}</CardText>
              <CardText style={cardTextStyle}><b>SP:</b> {char.max_sp}</CardText>
              <CardText style={cardTextStyle}><b>AP:</b> {char.max_ap}</CardText>
              <CardText style={cardTextStyle}><b>Neny:</b> {char.zeny}</CardText>
            </Col>
            <Col md={6}>
              <CardText style={cardTextStyle}><b>For:</b> {char.str}</CardText>
              <CardText style={cardTextStyle}><b>Agi:</b> {char.agi}</CardText>
              <CardText style={cardTextStyle}><b>Vit:</b> {char.vit}</CardText>
              <CardText style={cardTextStyle}><b>Int:</b> {char.int}</CardText>
              <CardText style={cardTextStyle}><b>Des:</b> {char.dex}</CardText>
              <CardText style={cardTextStyle}><b>Sor:</b> {char.luk}</CardText>
            </Col>
          </Row>
          {/* Adicione aqui mais campos que deseja mostrar */}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CharCard;
