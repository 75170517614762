import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };
  // Estilos do botão Logout
  const buttonStyle = {
    background: 'linear-gradient(to bottom, #ff66cc 0%, #cc66ff 100%)', // Gradiente de rosa para roxo
    color: '#FFFFFF', // Cor do texto
    border: '2px solid #ffd700', // Contorno dourado
    borderRadius: '20px', // Borda arredondada
    padding: '10px 20px', // Espaçamento interno
    fontSize: '18px', // Tamanho da fonte
    fontWeight: 'bold', // Peso da fonte
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)', // Sombra do texto
    boxShadow: 'inset 0px 0px 2px #000000', // Sombra interna escura
    cursor: 'pointer', // Cursor de mão quando passar o mouse sobre o botão
    outline: 'none', // Sem contorno ao focar
    transition: 'background-color 0.3s', // Transição suave para hover
  };

  return (
    <button 
      onClick={handleLogout} 
      style={buttonStyle}
      onMouseEnter={(e) => (e.target.style.background = 'linear-gradient(to bottom, #e64c8b 0%, #a864cf 100%)')} // Escurecer o gradiente ao passar o mouse
      onMouseLeave={(e) => (e.target.style.background = 'linear-gradient(to bottom, #ff66cc 0%, #cc66ff 100%)')} // Voltar ao gradiente original quando o mouse sair
    >
      Logout
    </button>
  );
};

export default LogoutButton;