// pages/CompraRops.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { Row, Container, Col } from 'reactstrap';
import withAuth from '../hoc/withAuth'; // Importe o HOC
import StatusAlert from './components/Status-Alert'; // Importe o componente StatusAlert
import CardRops from './components/CardRops';
import MyToast from './components/My-Toast';

function CompraRops(props) {
  const [inputValue, setInputValue] = useState('');
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [chars, setChars] = useState([]);
  // Aqui você pode definir os diferentes valores de ROPs em um array ou objeto
  const ropsOptions = [
    { id: 1, valorRops: 5000, custoUsdt: 1 },
    { id: 2, valorRops: 25000, custoUsdt: 5 },
    { id: 3, valorRops: 50000, custoUsdt: 10 },
    { id: 4, valorRops: 100000, custoUsdt: 20 },
    { id: 5, valorRops: 250000, custoUsdt: 50 },
    { id: 6, valorRops: 500000, custoUsdt: 100 },
    // Adicione mais opções conforme necessário
  ];

  const [feito, setFeito] = useState(false);
  const [balances, setBalances] = useState({});

  const { userStatus, isAdmin, wallet } = props;

  const fetchChars = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ADDRESS}getChars.php?token=`+token
      );
      setChars(response.data.data);
      setBalances({ cashpoints: response.data.cashPoints });
      
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    if (!feito) {
      fetchChars(); // Chame a função fetchChars no useEffect
      setFeito(true);
    }
  }, [showToast]);

  return (
    <div style={{ backgroundColor: '#dddddd', paddingBottom: 20 }}>
      <Navbar isAdmin={isAdmin} balances={balances} wallet={wallet}/>
      <MyToast isOpen={showToast} title={toastTitle} message={toastText} bgClass={bgClass}/>
      <Container>
        <Row>
          {ropsOptions.map((opcao) => (
            <Col md="2" key={opcao.id}>
              <CardRops valorRops={opcao.valorRops} custoUsdt={opcao.custoUsdt} walletAddress={wallet} toastText={setToastText} toastTittle={setToastTitle} toastBg={setBgClass} showToast={setShowToast} hideToast={hideToast}/>
            </Col>
          ))}
        </Row>
      </Container>
    </div>

  );
}


export default withAuth(CompraRops); // Use o HOC
