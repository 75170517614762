import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './Logout-Button';
import withAuth from '../../hoc/withAuth';
import BalanceCard from './Balance-Card';
import {
  Collapse,
  Navbar as RSNavbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  NavLink,
} from 'reactstrap';

import Web3 from 'web3';
import ConnectWalletButton from './ConnectWalletButton';
import logoImage from '../../imagens/logo.png';
import '../css/Navbar.css'; // Certifique-se de ter este arquivo CSS na pasta correta

const Navbar = (props) => {
  const { isAdmin, balances, wallet } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    // Limpa o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <RSNavbar dark expand="md" className="flex-column flex-md-row align-items-center" style={{backgroundColor: '#0d1429'}}>
      <NavbarToggler onClick={toggle} className="mr-2" />
      <div className={`${isLargeScreen ? 'd-flex order-1 order-md-1' : 'd-flex order-2 order-md-2'} `}>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="flex-grow-1" navbar>
            <NavItem>
              <Link className="nav-link button-fantasy" to="/dashboard">Dashboard</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link button-fantasy" to="/compraRops">Comprar Rops</Link>
            </NavItem>

            {//<NavItem>
              //<Link className="nav-link button-fantasy" to="/profile">Profile</Link>
            //</NavItem>
            }
            {isAdmin === 1 && (
              <NavItem>
                <Link className="nav-link button-fantasy" to="/admin/add-signals">Add Sinais</Link>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </div>
      <NavbarBrand className={`${isLargeScreen ? 'mx-auto order-2 order-md-2' : 'mx-auto order-1 order-md-1'} `} to="/" tag={Link}>
        <img src={logoImage} alt="Logo" style={{ maxWidth: '250px', height: 'auto' }}/>
      </NavbarBrand>
      <NavbarText className={`${isLargeScreen ? 'ml-auto d-flex align-items-center order-md-3 order-3' : 'ml-md-auto order-md-4 order-4'} `}>
        <ConnectWalletButton wallet={wallet}/>
        <BalanceCard balance={balances.cashpoints} />
        <LogoutButton />
      </NavbarText>
    </RSNavbar>
  );
};

export default withAuth(Navbar);