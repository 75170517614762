//./components/Balance-Card.js
import React, {useState, useEffect, useRef }from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardText
} from "reactstrap";


const BalanceCard = ({ balance }) => {
    
  
  useEffect(() => {
    
  });

  return (

    <Card className="my-2 p-0" inverse style={{ margin: 5, backgroundColor: "#00000099", borderRadius: 25, padding: 5, boxShadow: 'inset 0px 0px 2px #ffffff66' }}>
      <CardBody style={{padding: "0"}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', padding: 5}}>
              <div style={{position: 'absolute', top: '-16px', left: '50%', transform: 'translateX(-50%)', fontWeight: 'bold'}} className='text-outline'>Rops</div>
              <CardText style={{fontSize: "1em", fontWeight: "bold", paddingLeft: 15, paddingRight: 15}}>
                  {balance}
              </CardText>
          </div>
      </CardBody>
  </Card>

  );
};

export default BalanceCard;