import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  CardText
} from "reactstrap";

const ConnectWalletButton = (props) => {
  const { wallet } = props;
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const getAccount = async () => {
    if(wallet != "" && wallet != null){
      
    }else{
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length === 0) {
            console.log("Nenhuma conta disponível");
          } else {
            const account = accounts[0];
            console.log("Conta obtida:", account);
            localStorage.setItem('walletConnected', 'true');
            setWalletAddress(account);
            setIsConnected(true);
            // Enviar 'account' para o servidor para vincular à conta do usuário
            await axios.post(`${process.env.REACT_APP_API_ADDRESS}connectWallet.php`, { walletAddress: account, token: localStorage.getItem('token') });
            // Implemente mais lógica aqui, se necessário
          }
        } catch (error) {
          console.error("Erro ao obter contas:", error);
        }
      } else {
        console.error("MetaMask não está instalada");
      }
    }
  };

  useEffect(() => {
    if (wallet && wallet !== ""){
      setWalletAddress(wallet);
      setIsConnected(true);
      console.log(wallet);
    }
    const handleAccountsChanged = (accounts) => {
      // Adicione lógica para lidar com a mudança de contas
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [wallet]);

  return isConnected ? (
    <Card className="my-2 p-0" inverse style={{ margin: 5, backgroundColor: "#00000099", borderRadius: 25, padding: 5, boxShadow: 'inset 0px 0px 2px #ffffff66' }}>
        <CardBody style={{padding: "0"}}>
            <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex', padding: 5, paddingLeft: 15, paddingRight: 15, textAlign: 'center', justifyContent: 'center'}}>
              {`${walletAddress.substring(0, 6)}...${walletAddress.substring(walletAddress.length - 4)}`}
            </CardText>
        </CardBody>
    </Card>
  ) : (
    <button onClick={getAccount}>Conectar com MetaMask</button>
  );
};

export default ConnectWalletButton;
