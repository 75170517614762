//login.js
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Form, FormGroup, Button } from 'reactstrap';
import fundo from '../imagens/fundologin.png';
import logo from '../imagens/logo.png';
import LoginButton from './components/Login-Button';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');
    const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}login.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (res.ok) {
      const { token, decoded } = await res.json(); // Obter o token da resposta
      localStorage.setItem('token', token); // Armazenar o token no localStorage
      localStorage.setItem('decoded', decoded); // Armazenar o token no localStorage
      navigate('/dashboard');
    } else {
      const { message } = await res.json();
      setError(message);
    }
  };
  const textBoxStyle = {
    backgroundColor: 'white',
    borderRadius: '25px',
    height: '40px',
    minWidth: '300px',
    padding: '15px 30px 15px 30px',
  };

  return (
    <section className="vh-100" style={{ backgroundImage: `url(${fundo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Container className="py-5 h-100">
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col className="col-12 col-md-8 col-lg-6 col-xl-4">
            <Card className="card text-white text-center align-items-center" style={{ borderRadius: '0.5rem', backgroundColor: 'rgba(0, 0, 0, 0)', borderColor: 'rgba(0, 0, 0, 0)' }}>
            <CardHeader><img src={logo} alt="Logo" className='responsive-logo'/></CardHeader>
              <CardBody className="p-4" style={{ borderColor: 'rgba(0, 0, 0, 0)' }}>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="mb-3">
                    <input type="text" className="form-control form-control-sm" id="email" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} required style={textBoxStyle}/>
                    
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <input type="password" className="form-control form-control-sm" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required style={textBoxStyle}/>
                  </FormGroup>
                  {//<p className="small mb-2 pb-lg-2"><Link className="text-white-50" to='/forgot'>Forgot password?</Link></p>
                  }
                  <LoginButton />
                  {error && <div className="alert alert-danger" role="alert">{error}</div>}
                </Form>
              </CardBody>
              <CardFooter>
                {//<p className="mb-0">Don't have an account? <Link className="text-white-50 fw-bold" to="/signup">Sign Up</Link></p>
                }
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
